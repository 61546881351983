<template>
  <validation-observer #default="{handleSubmit}">
    <b-row
      v-if="loading"
      align-h="center"
    >
      <div
        class="loader"
      />
    </b-row>
    <b-form
      v-else
      @submit.prevent="handleSubmit(submitPoll)"
    >
      <validation-provider
        #default="validationContext"
        name="Question"
        rules="required"
      >
        <b-form-group
          label="Question"
          label-for="question"
        >
          <b-form-textarea
            id="question"
            v-model="poll.question"
            placeholder="Question"
            :state="getValidationState(validationContext)"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <div
        v-for="i in [0,1,2]"
        :key="i"
      >
        <validation-provider
          #default="validationContext"
          :name="`Answer ${i+1}`"
          rules="required"
        >
          <b-form-group
            :label="`Answer ${i+1}`"
            :label-for="`answer-${i+1}`"
          >
            <b-form-input
              :id="`answer-${i}`"
              :value="poll.answers[i].body"
              :placeholder="`Answer ${i+1}`"
              :state="getValidationState(validationContext)"
              @input="(answer)=>pushAnswer(answer,i)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </div>

      <validation-provider
        #default="validationContext"
        name="Right Answer"
        rules="required"
      >
        <b-form-group
          label="Right Answer"
          label-for="right-answer"
        >
          <b-form-select
            id="right-answer"
            v-model="poll.right_answer_temp"
            placeholder="Right Answer"
            :options="answersOptions"
            :state="getValidationState(validationContext)"
            @input="setRightAnswer"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-row>
        <b-col md="6">
          <date-time
            v-bind="{
              name:'Start Datetime',
              rules:'required',
              id:'start-datetime',
              config:{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                minDate: $moment().isAfter(livePeriod.startDate)?'today':livePeriod.startDate,
                maxDate:$moment(livePeriod.endDate).subtract({minutes:1}).format('YYYY-MM-DD HH:mm')
              },
            }"
            :datetime.sync="poll.valid_from"
          />
        </b-col>

        <b-col md="6">
          <date-time
            v-bind="{
              name:'End Datetime',
              rules:'required',
              id:'end-datetime',
              config:{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                minDate: $moment(poll.valid_from).add({minutes:1}).format('YYYY-MM-DD HH:mm'),
                maxDate:$moment(livePeriod.endDate).subtract({minutes:1}).format('YYYY-MM-DD HH:mm')},
            }"
            :datetime.sync="poll.valid_to"
          />
        </b-col>
      </b-form-row>

      <b-form-row>
        <back />

        <submit-button
          v-bind="{submit,handleSubmit}"
          class="ml-auto"
        />
      </b-form-row>
    </b-form>
  </validation-observer>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import DateTime from '@/common/components/common/FormInputs/DateTime.vue'
import LiveDatePeriod from '@/common/compositions/Activities/LiveDatePeriod'
import Back from '../common/FormInputs/Back.vue'
import SubmitButton from '../common/FormInputs/SubmitButton.vue'

export default {
  name: 'PollForm',
  components: { DateTime, Back, SubmitButton },
  props: {
    submit: { type: Function, default: () => null },
    poll: { type: Object, default: () => {} },
  },
  data() {
    return {
      live: { startDate: '', endDate: '' },
      right_answer_temp: '',
      answersOptions: [
        { value: 1, text: 'Answer 1' },
        { value: 2, text: 'Answer 2' },
        { value: 3, text: 'Answer 3' },
      ],
    }
  },
  created() {
    this.getLiveDatePeriod(this.$route.params.activityId)
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    const { getLiveDatePeriod, livePeriod, loading } = LiveDatePeriod()

    return {
      getValidationState, getLiveDatePeriod, livePeriod, loading,
    }
  },
  methods: {
    pushAnswer(answer, index) {
      this.poll.answers[index] = {
        body: answer, is_true: false, id: this.poll.answers[index].id, order: index + 1,
      }
    },
    setRightAnswer(index) {
      this.setAllAnswersToIncorrect()
      this.setRightAnswerToCorrect(index)
    },
    setAllAnswersToIncorrect() {
      this.poll.answers.forEach(ele => {
        ele.is_true = false
      })
    },
    setRightAnswerToCorrect(index) {
      this.poll.answers[index - 1].is_true = true
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
